import { Navigate, Outlet } from "react-router-dom";
const PrivateRoutes = () => {
  let isLoggedIn = localStorage.getItem("isLoggedIn");
  if(isLoggedIn){
    isLoggedIn = JSON.parse(isLoggedIn);
  }
  return isLoggedIn ? <Outlet /> : <Navigate to="/" />;
};

export { PrivateRoutes };
