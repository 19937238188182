import React, { useEffect, useRef, useState } from "react";
import { Button, Container, Form, Spinner } from "react-bootstrap";
import styles from "./PrivacyPolicyAdmin.module.css";
import { getContentApi, updatePrivacyPolicyApi } from "../../apis";
import { Editor } from "@tinymce/tinymce-react";

const PrivacyPolicyAdmin = () => {
  const [content, setContent] = useState<any>({});
  const [privacyPolicy, setPrivacyPolicy] = useState<string>("");
  const [termsAndConditions, setTermsAndConditions] = useState<string>("");
  const [errors, setErrors] = useState<any>({
    privacyPolicyError: "",
    termsAndConditionsError: "",
  });
  const [isLoadingSubmission, setIsLoadingSubmission] =
    useState<boolean>(false);
  const editorRef = useRef<any>(null);
  const onFetchContent = () => {
    getContentApi(
      (data) => {
        setContent(data.data);
      },
      () => {}
    );
  };

  useEffect(() => {
    onFetchContent();
  }, []);

  const validate = () => {
    let isValid = true;
    let _error = {
      privacyPolicyError: "",
      termsAndConditionsError: "",
    };
    if (privacyPolicy?.trim()?.length === 0) {
      isValid = false;
      _error = { ..._error, privacyPolicyError: "Field cannot be empty !" };
    }
    if (termsAndConditions?.trim()?.length === 0) {
      isValid = false;
      _error = {
        ..._error,
        termsAndConditionsError: "Field cannot be empty !",
      };
    }
    setErrors(_error);
    return isValid;
  };

  const onSubmitForm = () => {
    if (validate()) {
      setIsLoadingSubmission(true);
      const data = {
        id: content?.privacyPolicy?._id,
        privacy_policy_text: privacyPolicy,
        terms_and_conditions_text: termsAndConditions,
      };
      updatePrivacyPolicyApi(
        data,
        () => {
          onFetchContent();
          setIsLoadingSubmission(false);
        },
        () => {
          setIsLoadingSubmission(false);
        }
      );
    }
  };

  useEffect(() => {
    if (content?.privacyPolicy) {
      setPrivacyPolicy(content?.privacyPolicy?.privacy_policy_text);
      setTermsAndConditions(content?.privacyPolicy?.terms_and_conditions_text);
    }
  }, [content]);

  return (
    <Container className="mt-5">
      <Form className="w-lg-75">
        <Form.Group>
          <Form.Label>Privacy Policy</Form.Label>

          <Editor
            tinymceScriptSrc={"/tinymce/tinymce.min.js"}
            onInit={(evt, editor) => (editorRef.current = editor)}
            onEditorChange={(env) => {
              setPrivacyPolicy(env);
              setErrors({ ...errors, privacyPolicyError: "" });
            }}
            value={privacyPolicy}
            init={{
              height: 200,
              menubar: false,
              plugins: [
                "a11ychecker",
                "advlist",
                "advcode",
                "advtable",
                "autolink",
                "checklist",
                "export",
                "lists",
                "link",
                "image",
                "charmap",
                "preview",
                "anchor",
                "searchreplace",
                "visualblocks",
                "powerpaste",
                "fullscreen",
                "formatpainter",
                "insertdatetime",
                "media",
                "table",
                "help",
                "wordcount",
              ],
              font_size_formats: "8px 10px 12px 14px 16px 18px 24px 36px 48px",
              toolbar:
                "undo redo | casechange blocks fontsize | bold italic backcolor | " +
                "alignleft aligncenter alignright alignjustify | " +
                "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          />
          <p className={styles.errorMsg}>{errors.privacyPolicyError}</p>
        </Form.Group>
        <Form.Group>
          <Form.Label>Terms And Conditions</Form.Label>
          <Editor
            tinymceScriptSrc={"/tinymce/tinymce.min.js"}
            onInit={(evt, editor) => (editorRef.current = editor)}
            onEditorChange={(env) => {
              setTermsAndConditions(env);
              setErrors({ ...errors, termsAndConditionsError: "" });
            }}
            value={termsAndConditions}
            init={{
              height: 200,
              menubar: false,
              plugins: [
                "a11ychecker",
                "advlist",
                "advcode",
                "advtable",
                "autolink",
                "checklist",
                "export",
                "lists",
                "link",
                "image",
                "charmap",
                "preview",
                "anchor",
                "searchreplace",
                "visualblocks",
                "powerpaste",
                "fullscreen",
                "formatpainter",
                "insertdatetime",
                "media",
                "table",
                "help",
                "wordcount",
              ],
              font_size_formats: "8px 10px 12px 14px 16px 18px 24px 36px 48px",
              toolbar:
                "undo redo | casechange blocks fontsize | bold italic backcolor | " +
                "alignleft aligncenter alignright alignjustify | " +
                "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          />
          <p className={styles.errorMsg}>{errors.termsAndConditionsError}</p>
        </Form.Group>

        <Button onClick={onSubmitForm} className="ps-5 pe-5 mt-5">
          {isLoadingSubmission ? (
            <Spinner size={"sm"} animation="border" variant="primary" />
          ) : (
            "Update"
          )}
        </Button>
      </Form>
    </Container>
  );
};

export { PrivacyPolicyAdmin };
