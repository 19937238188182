import React, { useEffect, useState } from "react";

import styles from "./Blogs.module.css";
import { useNavigate, useParams } from "react-router-dom";
import {
  getBlogByCategoryApi,
  getBlogCategoryApi,
  getBlogsApi,
  getBlogsByCategoryNameApi,
} from "../../apis";
import moment from "moment";
import { RecentBlogs } from "./RecentBlogs";
import { BlogCategories } from "./BlogCategories";
import BlogSubscriber from "./BlogSubscriber";
import axios from "axios";

const Blogs = (props: any) => {
  const navigate = useNavigate();
  const { category_name } = useParams();
  const [blogs, setBlogs] = useState<any>();
  // const [responseData, setResponseData] = useState(null);
  // const [error, setError] = useState(null);

  useEffect(() => {
    let header = document.getElementById("site-header");
    if (header) {
      header?.classList?.add("header-black-bg");
    }
    window.scrollTo(0, 0);
  }, []);

  // const callJournalistApi = async () => {
  //   const integrationId = uuidv4();
  //   try {
  //     const response = await axios.post('https://api.tryjournalist.com/api/v0.1/batch', {
  //       key: '7a2a725f-741f-4716-9599-8971135c6de5',
  //       formula: {
  //         generation: {
  //           monitorId: 'sads',
  //           base: 'keyword+title',
  //           entries: [{keyword: '1000000', title: 'wsdsa'}]
  //         },
  //         content: {
  //           languageCode: 'en',
  //           countryCode: 'us',
  //           formality: 'formal',
  //           pointOfView: 'first-plural'
  //         },
  //         knowledge: {brandId: '10', serp: false},
  //         formatting: {
  //           bold: false,
  //           italics: false,
  //           tables: true,
  //           quotes: true,
  //           lists: true,
  //           headingCase: 'title'
  //         },
  //         structure: {
  //           faq: true,
  //           size: 'md',
  //           keyTakeaways: true,
  //           conclusion: true,
  //           ctaUrl: 'https://example.com',
  //           useCustomStructure: false
  //         },
  //         internalLinking: {
  //           sitemaps: [
  //             {
  //               url: 'https://example.com/sitemap.xml',
  //               pattern: '*.htm',
  //               exclude: 'old-pages/*'
  //             }
  //           ],
  //           linkDensity: 1
  //         },
  //         externalLinking: {
  //           externalLinks: [{url: 'https://ai.example.com', anchor: 'test'}],
  //           automateExternalLinks: true,
  //           includeSources: ['https://www.wikipedia.org'],
  //           excludeSources: ['https://unreliable-source.com']
  //         },
  //         images: {
  //           imageProvider: 'unsplash',
  //           inArticleImages: true,
  //           imageTags: ['AI'],
  //           imagePrompt: 'Test',
  //           featuredImage: true
  //         },
  //         videos: {
  //           youtubeLinks: ['https://www.youtube.com/watch?v=dQw4w9WgXcQ'],
  //           automateYoutubeLinks: false
  //         }
  //       },
  //       articleTags: ['technology'],
  //       integrationId: integrationId,
  //       quantity: 1,
  //     });

  //     setResponseData(response.data);
  //   } catch (err:any) {
  //     console.error(err);
  //     setError(err.message);
  //   }
  // };

  useEffect(() => {
    getBlogs();
  }, [category_name]);

  const getBlogs = async () => {
    if (category_name) {
      const result = await getBlogsByCategoryNameApi(category_name);
      if (result) {
        setBlogs(result);
      }
    } else {
      const result = await getBlogsApi();
      if (result) {
        setBlogs(result);
      }
    }
  };

  return (
    <div className="container pt-5">
      <div className="row">
        <div className={`col-lg-8 ${styles.verticalDivider} mt-5 pt-5`}>
          <div className="row mb-4">
            {blogs?.map((blog: any) => {
              return (
                <div
                  key={blog._id}
                  className="col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-4"
                  role="button"
                  onClick={() => {
                    const categoryName = blog?.blogCategory?.name
                      ?.split(" ")
                      ?.join("-");
                    let blogName = blog?.title?.split(" ")?.join("-");
                    blogName = blogName.split("/")?.join("{backslash}");
                    navigate(`/blogs/${categoryName}/${blogName}`);
                  }}
                >
                  <div className={`${styles._Card} p-1 border-0 rounded-3`}>
                    <div
                      className={`${styles.cardBody} d-flex flex-column text-center h-100`}
                    >
                      <div className="mb-3">
                        <img
                          src={blog.featureImage}
                          className={`img-fluid rounded-3 ${styles.zoomImage}`}
                          style={{
                            minHeight: "190px",
                            maxHeight: "190px",
                            objectFit: "cover",
                            width: "100%",
                          }}
                          alt="Blog Post"
                        />
                      </div>
                      <p
                        className="text-dark fw-bold mb-3"
                        style={{ fontSize: "0.9rem" }}
                      >
                        {moment(blog.createdAt).format("MMMM DD, YYYY")}
                      </p>
                      <h2
                        className={`h5 fw-bold text-dark ${styles.headingTitle} mb-3`}
                      >
                        <a href="javascript:void(0)" className="text-dark">
                          {blog.title}
                        </a>
                      </h2>
                      <p
                        className={`text-muted ${styles.cardHeading} ${styles.twoLineEllipsis}`}
                      >
                        {blog.excerpt}
                      </p>
                      <div className="mt-auto text-center">
                        <a
                          href="javascript:void(0)"
                          className={`btn mt-3 ${styles.btnReadMore}`}
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-lg-4 mt-5 pt-5">
          <RecentBlogs />
          <BlogCategories />
          <BlogSubscriber />

          {/* <button onClick={callJournalistApi}>Call API</button> */}
        </div>
      </div>
    </div>
  );
};

export { Blogs };
