import React, { useEffect, useState } from "react";
import styles from "./Blogs.module.css";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "react-bootstrap";
import {
  blogSubscribersApi,
  getBlogByCategoryAndBlognameApi,
  getContentApi,
} from "../../apis";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";

function BlogSubscriber() {
  const { category_name, blog_name } = useParams();
  const [email, setEmail] = useState<string>("");
  const [blog, setBlog] = useState<any>();
  const [openedModalName, setOpenedModalName] = useState("");
  const [content, setContent] = useState<any>({});
  const [errors, setErrors] = useState<any>({
    emailError: "",
  });
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getContentApi(
      (data) => {
        setContent(data.data);
      },
      () => {}
    );
  }, []);

  const validateEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(email);
  };

  const validate = () => {
    let isValid = true;
    let _error = { emailError: "" };

    if (email.trim().length === 0) {
      isValid = false;
      _error.emailError = "Email field cannot be empty!";
    } else if (!validateEmail(email)) {
      isValid = false;
      _error.emailError = "Please enter a valid email address!";
    }

    setErrors(_error);
    return isValid;
  };

  useEffect(() => {
    getBlogByCategoryNameAndBlogName();
  }, [blog_name, category_name]);

  const getBlogByCategoryNameAndBlogName = async () => {
    if (blog_name && category_name) {
      const result = await getBlogByCategoryAndBlognameApi(
        blog_name,
        category_name
      );
      setBlog(result);
    }
  };

  const onSubscribe = () => {
    setLoading(true)
    if (validate()) {
      blogSubscribersApi(
        {
          blogId: blog && blog._id,
          email: email,
        },
        (data) => {
          toast.success("Thank you for subscribing !", { autoClose: 3000 });
          setEmail("");
          setLoading(false)
        },
        (error) => {
          toast.error(error.message, { autoClose: 3000 });
          setLoading(false)
        }
      );
    }
  };

  const renderTermsAndConditionModal = () => {
    const modalContent =
      openedModalName === "termsAndCondition"
        ? blog?.privacyPolicy?.terms_and_conditions_text || ""
        : blog?.privacyPolicy?.privacy_policy_text || "";

    return (
      <Modal
        show={
          openedModalName === "termsAndCondition" ||
          openedModalName === "privacyPolicy"
        }
        size="xl"
        backdrop
        centered
        contentClassName={styles.modalContent}
        onHide={() => setOpenedModalName("")}
      >
        <ModalHeader
          closeButton
          closeVariant="white"
          className="border-bottom-0"
        >
          <h3 className="w-100 text-center text-white">
            {openedModalName === "termsAndCondition"
              ? "Terms and conditions !"
              : "Privacy Policy !"}
          </h3>
        </ModalHeader>
        <ModalBody className="text-white fw-light">
          <Container>
            <Row>
              <Col xs="12">
                <p className="font-16 line-height-24">
                  {openedModalName === "termsAndCondition"
                    ? parse(
                        content?.privacyPolicy?.terms_and_conditions_text || ""
                      )
                    : parse(content?.privacyPolicy?.privacy_policy_text || "")}
                </p>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    );
  };

  return (
    <div className={`${styles.recentArticles}`}>
      <h2 className={`${styles.sidebarTitle}`}>STAY IN THE KNOW</h2>
      <div className={`${styles.subscriptionBox}`}>
        <Form className="d-flex justify-content-center">
          <Form.Group>
            <Form.Control
              type="email"
              placeholder="Type your email..."
              className={styles.subscriptionInput}
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
                setErrors({ ...errors, emailError: "" });
              }}
            />
          </Form.Group>
          <Button
            variant="info"
            className={styles.subscribeBtn}
            onClick={onSubscribe}
          >
          {loading ?<Spinner  animation="border" />:"Subscribe"}
          </Button>
        </Form>
        {errors.emailError && (
          <p className={`${styles.errorMsg}`}>{errors.emailError}</p>
        )}
        <p className={`${styles.termsText} mt-4`}>
          By subscribing you agree to rorotechnology’s{" "}
          <a
            href="javascript:void(0)"
            onClick={() => setOpenedModalName("termsAndCondition")}
          >
            Terms & conditions
          </a> and {" "}
          <a
            href="javascript:void(0)"
            onClick={() => setOpenedModalName("privacyPolicy")}
          >
            Privacy Policy{" "}
          </a>
        </p>
      </div>
      {renderTermsAndConditionModal()}
    </div>
  );
}

export default BlogSubscriber;
