import { toast } from "react-toastify";
import { uploadFileApi } from "../apis";

const useContentLogic = () => {

  const uploadImageToS3Bucket = async (file: any, successCallback: any, errorCallback?: any) => {
    try {
      const formData=new FormData();
      formData.append("image", file);
      const result = await uploadFileApi(formData)
      successCallback(result);
    } catch (error) {
      toast.error("unable to upload image. Please try again later");
      console.error(error);
      errorCallback && errorCallback(error);
    }
  };

  return {
    uploadImageToS3Bucket,
  };
};

export { useContentLogic };
