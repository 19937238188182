import { toast } from "react-toastify";
import { axiosInstance } from "../axios";

const getContentApi = async (
  successCallback: (response: any) => void,
  errorCallback: (error: any) => void
) => {
  try {
    let response = await axiosInstance.get("/get-content");
    successCallback(response);
  } catch (error) {
    errorCallback(error);
    toast.error("something went wrong! ", { autoClose: 3000 });
  }
};

const updateBannerContentApi = async (
  body: any,
  successCallback: (response: any) => void,
  errorCallback: (error: any) => void
) => {
  try {
    let response = await axiosInstance.post("/banner/update", body);
    successCallback(response);
    toast.success("Updated Successfully !", { autoClose: 3000 });
  } catch (error) {
    errorCallback(error);
    toast.error("something went wrong! ", { autoClose: 3000 });
  }
};
const updateProductsContentApi = async (
  body: any,
  successCallback: (response: any) => void,
  errorCallback: (error: any) => void
) => {
  try {
    let response = await axiosInstance.post("/product/update", body);
    successCallback(response);
    toast.success("Updated Successfully !", { autoClose: 3000 });
  } catch (error) {
    errorCallback(error);
    toast.error("something went wrong! ", { autoClose: 3000 });
  }
};

const updatePortfolioContentApi = async (
  body: any,
  successCallback: (response: any) => void,
  errorCallback: (error: any) => void
) => {
  try {
    let response = await axiosInstance.post("/portfolio/update", body);
    successCallback(response);
    toast.success("Updated Successfully !", { autoClose: 3000 });
  } catch (error) {
    errorCallback(error);
    toast.error("something went wrong! ", { autoClose: 3000 });
  }
};

const updateServicesContentApi = async (
  body: any,
  successCallback: (response: any) => void,
  errorCallback: (error: any) => void
) => {
  try {
    let response = await axiosInstance.post("/services/update", body);
    successCallback(response);
    toast.success("Updated Successfully !", { autoClose: 3000 });
  } catch (error) {
    errorCallback(error);
    toast.error("something went wrong! ", { autoClose: 3000 });
  }
};

const updateTeamContentApi = async (
  body: any,
  successCallback: (response: any) => void,
  errorCallback: (error: any) => void
) => {
  try {
    let response = await axiosInstance.post("/team/update", body);
    successCallback(response);
    toast.success("Updated Successfully !", { autoClose: 3000 });
  } catch (error) {
    errorCallback(error);
    toast.error("something went wrong! ", { autoClose: 3000 });
  }
};

const updateAboutUsContentApi = async (
  body: any,
  successCallback: (response: any) => void,
  errorCallback: (error: any) => void
) => {
  try {
    let response = await axiosInstance.post("/about-us/update", body);
    successCallback(response);
    toast.success("Updated Successfully !", { autoClose: 3000 });
  } catch (error) {
    errorCallback(error);
    toast.error("something went wrong! ", { autoClose: 3000 });
  }
};

const sendEmailApi = async (
  body: any,
  successCallback: (response: any) => void,
  errorCallback: (error: any) => void
) => {
  try {
    let response = await axiosInstance.post("/send-mail", body);
    successCallback(response);
    toast.success("Success !", { autoClose: 3000 });
  } catch (error) {
    errorCallback(error);
    toast.error("something went wrong! ", { autoClose: 3000 });
  }
};

const updateSocialMediaApi = async (
  body: any,
  successCallback: (response: any) => void,
  errorCallback: (error: any) => void
) => {
  try {
    let response = await axiosInstance.post("/social/update", body);
    successCallback(response);
    toast.success("Updated Successfully !", { autoClose: 3000 });
  } catch (error) {
    errorCallback(error);
    toast.error("something went wrong! ", { autoClose: 3000 });
  }
};
const updatePrivacyPolicyApi = async (
  body: any,
  successCallback: (response: any) => void,
  errorCallback: (error: any) => void
) => {
  try {
    let response = await axiosInstance.post("/privacy/update", body);
    successCallback(response);
    toast.success("Updated Successfully !", { autoClose: 3000 });
  } catch (error) {
    errorCallback(error);
    toast.error("something went wrong! ", { autoClose: 3000 });
  }
};
const uploadFileApi = async (body: any) => {
  try {
    const response = await axiosInstance.post("/uploader/do", body);
    return response.data;
  } catch (error) {
    toast.error("something went wrong! ", { autoClose: 3000 });
  }
};

const addUpdateBlogCategoryApi = async (body: any) => {
  try {
    const response = await axiosInstance.post(
      "/blog-category/add/update",
      body
    );
    if (response) {
      toast.success("SuccessFul ", { autoClose: 3000 });
    }
    return response.data;
  } catch (error) {
    toast.error("something went wrong! ", { autoClose: 3000 });
  }
};

const getBlogCategoryApi = async () => {
  try {
    const response = await axiosInstance.get(
      "/blog-category/get-blog-categories"
    );
    return response.data;
  } catch (error) {
    toast.error("something went wrong! ", { autoClose: 3000 });
  }
};

const deleteBlogCategoryApi = async (id: string) => {
  try {
    const response = await axiosInstance.delete("/blog-category/" + id);
    if (response) {
      toast.success("Blog Category Deleted! ", { autoClose: 3000 });
    }
    return response.data;
  } catch (error) {
    toast.error("something went wrong! ", { autoClose: 3000 });
  }
};

const getBlogByCategoryApi = async (id: string) => {
  try {
    const response = await axiosInstance.get("blogs/get-all-blogs/" + id);
    return response.data;
  } catch (error) {
    toast.error("something went wrong! ", { autoClose: 3000 });
  }
};

const createBlogApi = async (blog: any) => {
  try {
    const response = await axiosInstance.post("blogs/add", blog);
    if (response) {
      toast.success("Blog Created ", { autoClose: 3000 });
    }
    return response.data;
  } catch (error) {
    toast.error("something went wrong! ", { autoClose: 3000 });
  }
};

const editBlogApi = async (blog: any) => {
  try {
    const response = await axiosInstance.post("blogs/edit/"+blog._id, blog);
    if (response) {
      toast.success("Blog Updated ", { autoClose: 3000 });
    }
    return response.data;
  } catch (error) {
    toast.error("something went wrong! ", { autoClose: 3000 });
  }
};

const getBlogByCategoryAndBlognameApi = async (
  blogname: string,
  categoryname: string
) => {
  try {
    const response = await axiosInstance.get(
      `blogs/get/by/${categoryname}/${blogname}`
    );
    return response.data;
  } catch (error) {
    toast.error("something went wrong! ", { autoClose: 3000 });
  }
};

const getRecentBlogsApi = async () => {
  try {
    const response = await axiosInstance.get(`blogs/get-recent`);
    return response.data;
  } catch (error) {
    toast.error("something went wrong! ", { autoClose: 3000 });
  }
};

const getBlogsApi = async () => {
  try {
    const response = await axiosInstance.get(`blogs/get-all-blogs`);
    return response.data;
  } catch (error) {
    toast.error("something went wrong! ", { autoClose: 3000 });
  }
};

const getBlogsByCategoryNameApi = async (category_name: string) => {
  try {
    const response = await axiosInstance.get(`blogs/fetch/by/${category_name}`);
    return response.data;
  } catch (error) {
    toast.error("something went wrong! ", { autoClose: 3000 });
  }
};

const blogSubscribersApi = async (
  body: any,
  successCallback: (response: any) => void,
  errorCallback: (error: any) => void) => {
  try {
    let response = await axiosInstance.post("blogs-subscribers/add", body);
    successCallback(response);
  } catch (error: any) {
    errorCallback(error);
    toast.error(error?.response?.data?.message || "something went wrong! ", {
      autoClose: 3000,
    });
  }
};

const getBlogByIdApi= async (_id:any, 
  successCallback: (response: any) => void,
  errorCallback: (error: any) => void) =>{
  try {
    const response = await axiosInstance.get("blogs/get-by-id/"+_id);
    successCallback(response);
  } catch (error) {
    errorCallback(error)
  }
}


export {
  getContentApi,
  updateBannerContentApi,
  updateProductsContentApi,
  updatePortfolioContentApi,
  updateServicesContentApi,
  updateTeamContentApi,
  sendEmailApi,
  updateSocialMediaApi,
  updatePrivacyPolicyApi,
  updateAboutUsContentApi,
  uploadFileApi,
  addUpdateBlogCategoryApi,
  getBlogCategoryApi,
  deleteBlogCategoryApi,
  getBlogByCategoryApi,
  createBlogApi,
  getBlogByCategoryAndBlognameApi,
  getRecentBlogsApi,
  getBlogsApi,
  getBlogsByCategoryNameApi,
  blogSubscribersApi,
  editBlogApi,
  getBlogByIdApi
};
