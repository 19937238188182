import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Container,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
  Table,
} from "react-bootstrap";
import styles from "./ProductsAdmin.module.css";
import { getContentApi, updateProductsContentApi } from "../../apis";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faGear } from "@fortawesome/free-solid-svg-icons";
import { Editor } from "@tinymce/tinymce-react";
import parse from "html-react-parser";

const ProductsAdmin = () => {
  const [content, setContent] = useState<any>({});
  const [description, setDescription] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [items, setItems] = useState<Array<any>>([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [selectedElement, setSelectedElement] = useState<any>();
  const [selectedElementIndex, setSelectedElementIndex] = useState<any>();
  const [errors, setErrors] = useState<any>({
    titleError: "",
    descriptionError: "",
    subTitleError: "",
  });
  const [isLoadingSubmission, setIsLoadingSubmission] =
    useState<boolean>(false);
  const editorRef = useRef<any>(null);

  const onFetchContent = () => {
    getContentApi(
      (data) => {
        setContent(data.data);
      },
      () => {}
    );
  };

  useEffect(() => {
    onFetchContent();
  }, []);

  const validate = () => {
    let isValid = true;
    let _error = {
      titleError: "",
      subTitleError: "",
      descriptionError: "",
    };
    if (title?.trim()?.length === 0) {
      isValid = false;
      _error = { ..._error, titleError: "Field cannot be empty !" };
    }
    if (description?.trim()?.length === 0) {
      isValid = false;
      _error = { ..._error, descriptionError: "Field cannot be empty !" };
    }
    setErrors(_error);
    return isValid;
  };

  const onSubmitForm = () => {
    if (validate()) {
      setIsLoadingSubmission(true);
      const data = {
        id: content?.product?._id,
        title,
        description,
        items,
      };
      updateProductsContentApi(
        data,
        () => {
          onFetchContent();
          setIsLoadingSubmission(false);
        },
        () => {
          setIsLoadingSubmission(false);
        }
      );
    }
  };

  const onSubmitEditOrCreate = (data: any) => {
    if (validate()) {
      setIsLoadingSubmission(true);
      updateProductsContentApi(
        data,
        () => {
          onFetchContent();
          setIsLoadingSubmission(false);
        },
        () => {
          setIsLoadingSubmission(false);
        }
      );
    }
  };

  useEffect(() => {
    if (content?.product) {
      setTitle(content?.product?.title);
      setDescription(content?.product?.description);
      setItems(content?.product?.items || []);
    }
  }, [content]);

  const renderEditModal = () => {
    return (
      <Modal
        show={isEditModalOpen}
        size="lg"
        backdrop="static"
        centered
        contentClassName={styles.modalContent}
        onHide={() => setIsEditModalOpen(false)}
      >
        <ModalHeader
          closeButton
          closeVariant="black"
          // className="border-bottom-0"
        >
          <Modal.Title>Edit Product</Modal.Title>
        </ModalHeader>
        <ModalBody className="fw-light">
          <Container>
            <Form className="w-lg-75">
              <Form.Group>
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedElement?.title}
                  onChange={(event) => {
                    setSelectedElement({
                      ...selectedElement,
                      title: event.target.value,
                    });
                  }}
                  placeholder="Title"
                  className={styles.formItem}
                />
                <p className={styles.errorMsg}>{errors.titleError}</p>
              </Form.Group>
              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Editor
                  tinymceScriptSrc={"/tinymce/tinymce.min.js"}
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  onEditorChange={(env) => {
                    setSelectedElement({
                      ...selectedElement,
                      description: env,
                    });
                  }}
                  value={selectedElement?.description}
                  init={{
                    height: 200,
                    menubar: false,
                    plugins: [
                      "a11ychecker",
                      "advlist",
                      "advcode",
                      "advtable",
                      "autolink",
                      "checklist",
                      "export",
                      "lists",
                      "link",
                      "image",
                      "charmap",
                      "preview",
                      "anchor",
                      "searchreplace",
                      "visualblocks",
                      "powerpaste",
                      "fullscreen",
                      "formatpainter",
                      "insertdatetime",
                      "media",
                      "table",
                      "help",
                      "wordcount",
                    ],
                     font_size_formats: "8px 10px 12px 14px 16px 18px 24px 36px 48px",
              toolbar:
                      "undo redo | casechange blocks fontsize | bold italic backcolor | " +
                      "alignleft aligncenter alignright alignjustify | " +
                      "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                  }}
                />
                <p className={styles.errorMsg}>{errors.descriptionError}</p>
              </Form.Group>
            </Form>
            <Button
              onClick={() => {
                let _items = items;
                _items[selectedElementIndex] = selectedElement;
                setItems(_items);
                onSubmitEditOrCreate({
                  id: content?.product?._id,
                  title,
                  description,
                  items: _items,
                });
                setIsEditModalOpen(false);
                setSelectedElement({});
                setSelectedElementIndex(undefined);
              }}
              className="ps-5 pe-5 mt-5"
            >
              EDIT
            </Button>
          </Container>
        </ModalBody>
      </Modal>
    );
  };

  return (
    <Container className="mt-5">
      <Form className="w-lg-75">
        <Form.Group>
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            value={title}
            onChange={(event) => {
              setTitle(event.target.value);
              setErrors({ ...errors, titleError: "" });
            }}
            placeholder="Title"
            className={styles.formItem}
          />
          <p className={styles.errorMsg}>{errors.titleError}</p>
        </Form.Group>
        <Form.Group>
          <Form.Label>Description</Form.Label>
          <Editor
            tinymceScriptSrc={"/tinymce/tinymce.min.js"}
            onInit={(evt, editor) => (editorRef.current = editor)}
            onEditorChange={(env) => {
              setDescription(env);
              setErrors({ ...errors, firstTitleError: "" });
            }}
            value={description}
            init={{
              height: 200,
              menubar: false,
              plugins: [
                "a11ychecker",
                "advlist",
                "advcode",
                "advtable",
                "autolink",
                "checklist",
                "export",
                "lists",
                "link",
                "image",
                "charmap",
                "preview",
                "anchor",
                "searchreplace",
                "visualblocks",
                "powerpaste",
                "fullscreen",
                "formatpainter",
                "insertdatetime",
                "media",
                "table",
                "help",
                "wordcount",
              ],
              font_size_formats: "8px 10px 12px 14px 16px 18px 24px 36px 48px",
              toolbar:
                "undo redo | casechange blocks fontsize | bold italic backcolor | " +
                "alignleft aligncenter alignright alignjustify | " +
                "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          />
          <p className={styles.errorMsg}>{errors.descriptionError}</p>
        </Form.Group>
      </Form>
      <Table bordered responsive hover size="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>Title</th>
            <th>Description</th>
            <th>{<FontAwesomeIcon className="ps-2 pe-2" icon={faGear} />}</th>
          </tr>
        </thead>
        <tbody>
          {items?.map((item: any, index: number) => {
            return (
              <tr>
                <td>{index + 1}</td>
                <td>{item.title}</td>
                <td>{parse(item.description || "")}</td>
                <td>
                  <FontAwesomeIcon
                    onClick={() => {
                      setIsEditModalOpen(true);
                      setSelectedElement(item);
                      setSelectedElementIndex(index);
                    }}
                    className="ps-2 pe-2"
                    role={"button"}
                    icon={faEdit}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Button onClick={onSubmitForm} className="ps-5 pe-5 mt-5">
        {isLoadingSubmission ? (
          <Spinner size={"sm"} animation="border" variant="primary" />
        ) : (
          "Update"
        )}
      </Button>
      {renderEditModal()}
    </Container>
  );
};

export { ProductsAdmin };
