import React, { useEffect, useState } from "react";
import { getBlogCategoryApi } from "../../apis";
import styles from "./Blogs.module.css";
import { Link } from "react-router-dom";

const BlogCategories = () => {
  const [categories, setCategories] = useState<any>();
  const getCategories = async () => {
    const result = await getBlogCategoryApi();
    if (result?.length) {
      setCategories(result);
    }
  };
  useEffect(() => {
    getCategories();
  }, []);
  return (
    <div className={`${styles.recentArticles} mb-4`}>
      <h2 className={`${styles.sidebarTitle}`}>Categories</h2>
      <ul className="list-unstyled">
        {categories?.map((category: any) => {
          const categoryName = category?.name?.split(" ")?.join("-");
          return (
            <li className="text-center" key={category?._id}>
              <Link to={"/blogs/" + categoryName}>{category?.name}</Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export { BlogCategories };
