import React, { useEffect, useState } from "react";
import {
  AboutUs,
  AboutUsAdmin,
  BannerAdmin,
  ChangePasswordAdmin,
  Home,
  LoginAdmin,
  PortfolioAdmin,
  PrivacyPolicyAdmin,
  ProductsAdmin,
  ServicesAdmin,
  SocialMediaAdmin,
  TeamAdmin,
} from "./pages";
import { AdminLayout, FullPageLayout } from "./layouts";
import { Routes, Route } from "react-router-dom";
import { Buffer } from "buffer";
import { PrivateRoutes, LoggedInPrivateRoutes } from "./utils";
import { ToastContainer } from "react-toastify";
import { getContentApi } from "./apis";
import 'react-toastify/dist/ReactToastify.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-phone-input-2/lib/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { Blogs } from "./pages/Blogs";
import { CategoryAdmin } from "./pages/CategoryAdmin";
import { BlogsAdmin } from "./pages/BlogsAdmin";
import { CreateBlog } from "./pages/BlogsAdmin/CreateBlog";
import { BlogDetails } from "./pages/Blogs/BlogDetails";
import { EditBlog } from "./pages/BlogsAdmin/EditBlog";

function App() {
  Buffer.from("anything", "base64");
  window.Buffer = window.Buffer || require("buffer").Buffer;

  const [content, setContent] = useState<any>({});


  useEffect(()=>{
    getContentApi(
      (data) => {
        setContent(data.data);
      },
      () => {}
    );
  },[])

  return (
    <div className="App d-flex h-100">
      <Routes>
        <Route element={<FullPageLayout content = {content} />} >
         <Route path="/" element={<Home content={content} />} />
         <Route path="/about-us" element={<AboutUs content={content} />} />
         <Route path="/blogs" element={<Blogs />} />
         <Route path="/blogs/:category_name" element={<Blogs />} />
         <Route path="/blogs/:category_name/:blog_name" element={<BlogDetails />} />
        </Route>
        <Route element={<LoggedInPrivateRoutes />}>
          <Route path="admin" element={<LoginAdmin />} />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route path="admin/*" element={<AdminLayout />}>
            <Route path="banner" element={<BannerAdmin />} />
            <Route path="products" element={<ProductsAdmin />} />
            <Route path="portfolio" element={<PortfolioAdmin />} />
            <Route path="services" element={<ServicesAdmin />} />
            <Route path="team" element={<TeamAdmin />} />
            <Route path="social-media" element={<SocialMediaAdmin />} />
            <Route path="privacy-policy" element={<PrivacyPolicyAdmin />} />
            <Route path="about" element={<AboutUsAdmin />} />
            <Route path="change-password" element={<ChangePasswordAdmin />} />
            <Route path="blog-category" element={<CategoryAdmin />} />
            <Route path="blogs" element={<BlogsAdmin />} />
            <Route path="blogs/create" element={<CreateBlog />} />
            <Route path="blogs/edit/:id" element={<EditBlog />} />
          </Route>
        </Route>
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
