import React, { useEffect, useRef, useState } from "react";
import { Button, Container, Form, Spinner } from "react-bootstrap";
import styles from "./BannerAdmin.module.css";
import { getContentApi, updateBannerContentApi } from "../../apis";
import { Editor } from "@tinymce/tinymce-react";

const BannerAdmin = () => {
  const [content, setContent] = useState<any>({});
  const [description, setDescription] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [subTitle, setSubTitle] = useState<string>("");
  const [modalTitle, setModalTitle] = useState<string>("");
  const [modalLeftContent, setModalLeftContent] = useState<string>("");
  const [modalRightContent, setModalRightContent] = useState<string>("");
  const [errors, setErrors] = useState<any>({
    titleError: "",
    descriptionError: "",
    subTitleError: "",
    modalTitleError: "",
    leftContentError: "",
    rightContentError: "",
  });
  const [isLoadingSubmission, setIsLoadingSubmission] =
    useState<boolean>(false);
  const editorRef = useRef<any>(null);

  const onFetchContent = () => {
    getContentApi(
      (data) => {
        setContent(data.data);
      },
      () => {}
    );
  };

  useEffect(() => {
    onFetchContent();
  }, []);

  const validate = () => {
    let isValid = true;
    let _error = {
      titleError: "",
      subTitleError: "",
      descriptionError: "",
      modalTitleError: "",
      leftContentError: "",
      rightContentError: "",
    };
    if (title?.trim()?.length === 0) {
      isValid = false;
      _error = { ..._error, titleError: "Field cannot be empty !" };
    }
    if (subTitle?.trim()?.length === 0) {
      isValid = false;
      _error = { ..._error, subTitleError: "Field cannot be empty !" };
    }
    if (description?.trim()?.length === 0) {
      isValid = false;
      _error = { ..._error, descriptionError: "Field cannot be empty !" };
    }
    if (modalTitle?.trim()?.length === 0) {
      isValid = false;
      _error = { ..._error, modalTitleError: "Field cannot be empty !" };
    }
    if (modalLeftContent?.trim()?.length === 0) {
      isValid = false;
      _error = { ..._error, leftContentError: "Field cannot be empty !" };
    }
    if (modalRightContent?.trim()?.length === 0) {
      isValid = false;
      _error = { ..._error, rightContentError: "Field cannot be empty !" };
    }
    setErrors(_error);
    return isValid;
  };

  const onSubmitForm = () => {
    if (validate()) {
      setIsLoadingSubmission(true);
      const data = {
        id: content?.banner?._id,
        title,
        subTitle,
        description,
        modalTitle,
        modalLeftContent,
        modalRightContent
      };
      updateBannerContentApi(
        data,
        () => {
          onFetchContent();
          setIsLoadingSubmission(false);
        },
        () => {
          setIsLoadingSubmission(false);
        }
      );
    }
  };

  useEffect(() => {
    if (content?.banner) {
      setTitle(content?.banner?.title);
      setSubTitle(content?.banner?.subTitle);
      setDescription(content?.banner?.description);
      setModalTitle(content?.banner?.modalTitle);
      setModalLeftContent(content?.banner?.modalLeftContent);
      setModalRightContent(content?.banner?.modalRightContent);
    }
  }, [content]);

  return (
    <Container className="mt-5">
      <Form className="w-lg-75">
        <Form.Group>
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            value={title}
            onChange={(event) => {
              setTitle(event.target.value);
              setErrors({ ...errors, titleError: "" });
            }}
            placeholder="Title"
            className={styles.formItem}
          />
          <p className={styles.errorMsg}>{errors.titleError}</p>
        </Form.Group>
        <Form.Group>
          <Form.Label>Sub Title</Form.Label>
          <Form.Control
            type="text"
            value={subTitle}
            onChange={(event) => {
              setSubTitle(event.target.value);
              setErrors({ ...errors, subTitleError: "" });
            }}
            placeholder="Sub Title"
            className={styles.formItem}
          />
          <p className={styles.errorMsg}>{errors.subTitleError}</p>
        </Form.Group>
        <Form.Group>
          <Form.Label>Description</Form.Label>
          <Editor
            tinymceScriptSrc={"/tinymce/tinymce.min.js"}
            onInit={(evt, editor) => (editorRef.current = editor)}
            onEditorChange={(env) => {
              setDescription(env);
              setErrors({ ...errors, descriptionError: "" });
            }}
            value={description}
            init={{
              height: 200,
              menubar: false,
              plugins: [
                "a11ychecker",
                "advlist",
                "advcode",
                "advtable",
                "autolink",
                "checklist",
                "export",
                "lists",
                "link",
                "image",
                "charmap",
                "preview",
                "anchor",
                "searchreplace",
                "visualblocks",
                "powerpaste",
                "fullscreen",
                "formatpainter",
                "insertdatetime",
                "media",
                "table",
                "help",
                "wordcount",
              ],
              font_size_formats: "8px 10px 12px 14px 16px 18px 24px 36px 48px",
              toolbar:
                "undo redo | casechange blocks fontsize | bold italic backcolor | " +
                "alignleft aligncenter alignright alignjustify | " +
                "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          />
          <p className={styles.errorMsg}>{errors.descriptionError}</p>
        </Form.Group>

        <Form.Group>
          <Form.Label>Modal Title</Form.Label>
          <Form.Control
            type="text"
            value={modalTitle}
            onChange={(event) => {
              setModalTitle(event.target.value);
              setErrors({ ...errors, modalTitleError: "" });
            }}
            placeholder="Modal Title"
            className={styles.formItem}
          />
          <p className={styles.errorMsg}>{errors.modalTitleError}</p>
        </Form.Group>

        <Form.Group>
          <Form.Label>Modal Left Content</Form.Label>
          <Editor
            tinymceScriptSrc={"/tinymce/tinymce.min.js"}
            onInit={(evt, editor) => (editorRef.current = editor)}
            onEditorChange={(env) => {
              setModalLeftContent(env);
              setErrors({ ...errors, leftContentError: "" });
            }}
            value={modalLeftContent}
            init={{
              height: 200,
              menubar: false,
              plugins: [
                "a11ychecker",
                "advlist",
                "advcode",
                "advtable",
                "autolink",
                "checklist",
                "export",
                "lists",
                "link",
                "image",
                "charmap",
                "preview",
                "anchor",
                "searchreplace",
                "visualblocks",
                "powerpaste",
                "fullscreen",
                "formatpainter",
                "insertdatetime",
                "media",
                "table",
                "help",
                "wordcount",
              ],
              font_size_formats: "8px 10px 12px 14px 16px 18px 24px 36px 48px",
              toolbar:
                "undo redo | casechange blocks fontsize | bold italic backcolor | " +
                "alignleft aligncenter alignright alignjustify | " +
                "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          />
          <p className={styles.errorMsg}>{errors.leftContentError}</p>
        </Form.Group>

        <Form.Group>
          <Form.Label>Modal Right Content</Form.Label>
          <Editor
            tinymceScriptSrc={"/tinymce/tinymce.min.js"}
            onInit={(evt, editor) => (editorRef.current = editor)}
            onEditorChange={(env) => {
              setModalRightContent(env);
              setErrors({ ...errors, rightContentError: "" });
            }}
            value={modalRightContent}
            init={{
              height: 200,
              menubar: false,
              plugins: [
                "a11ychecker",
                "advlist",
                "advcode",
                "advtable",
                "autolink",
                "checklist",
                "export",
                "lists",
                "link",
                "image",
                "charmap",
                "preview",
                "anchor",
                "searchreplace",
                "visualblocks",
                "powerpaste",
                "fullscreen",
                "formatpainter",
                "insertdatetime",
                "media",
                "table",
                "help",
                "wordcount",
              ],
              font_size_formats: "8px 10px 12px 14px 16px 18px 24px 36px 48px",
              toolbar:
                "undo redo | casechange blocks fontsize | bold italic backcolor | " +
                "alignleft aligncenter alignright alignjustify | " +
                "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          />
          <p className={styles.errorMsg}>{errors.rightContentError}</p>
        </Form.Group>

        <Button onClick={onSubmitForm} className="ps-5 pe-5 mt-5">
          {isLoadingSubmission ? (
            <Spinner size={"sm"} animation="border" variant="primary" />
          ) : (
            "Update"
          )}
        </Button>
      </Form>
    </Container>
  );
};

export { BannerAdmin };
