import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import styles from "./ServicesAdmin.module.css";
import { getContentApi, updateServicesContentApi } from "../../apis";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faGear, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useContentLogic } from "../../hooks/useContentLogic";
import { Editor } from "@tinymce/tinymce-react";
import parse from "html-react-parser";

const ServicesAdmin = () => {
  const [content, setContent] = useState<any>({});
  const [title, setTitle] = useState<string>("");
  const [items, setItems] = useState<Array<any>>([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [selectedElement, setSelectedElement] = useState<any>();
  const [createElement, setCreateElement] = useState<any>();
  const [selectedImage, setSelectedImage] = useState<any>();
  const [selectedElementIndex, setSelectedElementIndex] = useState<any>();
  const [errors, setErrors] = useState<any>({
    titleError: "",
  });
  const [isLoadingSubmission, setIsLoadingSubmission] =
    useState<boolean>(false);
  const [isImageLoading, setIsImageLoading] = useState<boolean>(false);
  const editorRef = useRef<any>(null);
  const { uploadImageToS3Bucket } = useContentLogic();
  const onFetchContent = () => {
    getContentApi(
      (data) => {
        setContent(data.data);
      },
      () => {}
    );
  };

  useEffect(() => {
    onFetchContent();
  }, []);

  const validate = () => {
    let isValid = true;
    let _error = {
      titleError: "",
    };
    if (title?.trim()?.length === 0) {
      isValid = false;
      _error = { ..._error, titleError: "Field cannot be empty !" };
    }
    setErrors(_error);
    return isValid;
  };

  const onSubmitForm = () => {
    if (validate()) {
      setIsLoadingSubmission(true);
      const data = {
        id: content?.services?._id,
        title,
        items,
      };
      updateServicesContentApi(
        data,
        () => {
          onFetchContent();
          setIsLoadingSubmission(false);
        },
        () => {
          setIsLoadingSubmission(false);
        }
      );
    }
  };

  const onSubmitEditOrCreate = (data: any) => {
    if (validate()) {
      setIsLoadingSubmission(true);
      updateServicesContentApi(
        data,
        () => {
          onFetchContent();
          setIsLoadingSubmission(false);
        },
        () => {
          setIsLoadingSubmission(false);
        }
      );
    }
  };

  useEffect(() => {
    if (content?.services) {
      setTitle(content?.services?.title);
      setItems(content?.services?.items || []);
    }
  }, [content]);

  const renderEditModal = () => {
    return (
      <Modal
        show={isEditModalOpen}
        size="lg"
        backdrop="static"
        centered
        contentClassName={styles.modalContent}
        onHide={() => {
          setIsEditModalOpen(false);
          setSelectedElement({});
          setSelectedElementIndex(0);
          setSelectedImage(undefined);
        }}
      >
        <ModalHeader closeButton closeVariant="black">
        <Modal.Title>Edit Service</Modal.Title>
        </ModalHeader>
        <ModalBody className="fw-light">
          <Container>
            <Form className="w-lg-75">
              <Form.Group>
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedElement?.title}
                  onChange={(event) => {
                    setSelectedElement({
                      ...selectedElement,
                      title: event.target.value,
                    });
                  }}
                  placeholder="Title"
                  className={styles.formItem}
                />
                <p className={styles.errorMsg}>{errors.titleError}</p>
              </Form.Group>
              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Editor
                  tinymceScriptSrc={"/tinymce/tinymce.min.js"}
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  onEditorChange={(env) => {
                    setSelectedElement({
                      ...selectedElement,
                      description: env,
                    });
                  }}
                  value={selectedElement?.description}
                  init={{
                    height: 200,
                    menubar: false,
                    plugins: [
                      "a11ychecker",
                      "advlist",
                      "advcode",
                      "advtable",
                      "autolink",
                      "checklist",
                      "export",
                      "lists",
                      "link",
                      "image",
                      "charmap",
                      "preview",
                      "anchor",
                      "searchreplace",
                      "visualblocks",
                      "powerpaste",
                      "fullscreen",
                      "formatpainter",
                      "insertdatetime",
                      "media",
                      "table",
                      "help",
                      "wordcount",
                    ],
                     font_size_formats: "8px 10px 12px 14px 16px 18px 24px 36px 48px",
              toolbar:
                      "undo redo | casechange blocks fontsize | bold italic backcolor | " +
                      "alignleft aligncenter alignright alignjustify | " +
                      "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                  }}
                />
                <p className={styles.errorMsg}>{errors.descriptionError}</p>
              </Form.Group>
              <Form.Group>
                <Form.Label>Modal Description</Form.Label>
                <Editor
                  tinymceScriptSrc={"/tinymce/tinymce.min.js"}
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  onEditorChange={(env) => {
                    setSelectedElement({
                      ...selectedElement,
                      modal_description: env,
                    });
                  }}
                  value={selectedElement?.modal_description}
                  init={{
                    height: 200,
                    menubar: false,
                    plugins: [
                      "a11ychecker",
                      "advlist",
                      "advcode",
                      "advtable",
                      "autolink",
                      "checklist",
                      "export",
                      "lists",
                      "link",
                      "image",
                      "charmap",
                      "preview",
                      "anchor",
                      "searchreplace",
                      "visualblocks",
                      "powerpaste",
                      "fullscreen",
                      "formatpainter",
                      "insertdatetime",
                      "media",
                      "table",
                      "help",
                      "wordcount",
                    ],
                     font_size_formats: "8px 10px 12px 14px 16px 18px 24px 36px 48px",
              toolbar:
                      "undo redo | casechange blocks fontsize | bold italic backcolor | " +
                      "alignleft aligncenter alignright alignjustify | " +
                      "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                  }}
                />
                <p className={styles.errorMsg}>
                  {errors.modalDescriptionError}
                </p>
              </Form.Group>
            </Form>
            <Card>
              {isImageLoading ? (
                <Spinner
                  className="align-self-center m-5"
                  animation="border"
                  size="sm"
                />
              ) : (
                <Card.Img
                  variant="top"
                  className={styles.card_img}
                  src={
                    selectedImage ? selectedImage : selectedElement?.modal_img
                  }
                  onClick={() => {
                    let imageUploader = document.getElementById(
                      "product_image_uploader"
                    );
                    if (imageUploader) {
                      imageUploader.click();
                    }
                  }}
                />
              )}
              <Row>
                <input
                  onChange={(event: any) => {
                    setIsImageLoading(true);
                    uploadImageToS3Bucket(
                      event.target.files[0],
                      (data: any) => {
                        setSelectedImage(data);
                        setSelectedElement({
                          ...selectedElement,
                          modal_img: data,
                        });
                        setIsImageLoading(false);
                      },
                      (error: any) => {
                        setIsImageLoading(false);
                      }
                    );
                  }}
                  type={"file"}
                  accept=".png"
                  style={{ display: "none" }}
                  id="product_image_uploader"
                />
              </Row>
            </Card>
            {!isImageLoading && (
              <Button
                onClick={() => {
                  let _items = items;
                  _items[selectedElementIndex] = selectedElement;
                  setItems(_items);
                  onSubmitEditOrCreate({
                    id: content?.services?._id,
                    title,
                    items: _items,
                  });
                  setIsEditModalOpen(false);
                  setSelectedElement({});
                  setSelectedElementIndex(undefined);
                  setSelectedImage(undefined);
                }}
                className="ps-5 pe-5 mt-5"
              >
                EDIT
              </Button>
            )}
          </Container>
        </ModalBody>
      </Modal>
    );
  };

  const renderCreateModal = () => {
    return (
      <>
        <Modal
          onHide={() => {
            setIsCreateModalOpen(!isCreateModalOpen);
            setSelectedImage(undefined);
          }}
          show={isCreateModalOpen}
        >
          <Modal.Header closeButton>
          <Modal.Title>Add New Service</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form className="w-lg-75">
              <Form.Group>
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  value={createElement?.title}
                  onChange={(event) => {
                    setCreateElement({
                      ...createElement,
                      title: event.target.value,
                    });
                  }}
                  placeholder="Title"
                  className={styles.formItem}
                />
                <p className={styles.errorMsg}>{errors.titleError}</p>
              </Form.Group>
              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Editor
                  tinymceScriptSrc={"/tinymce/tinymce.min.js"}
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  onEditorChange={(env) => {
                    setCreateElement({
                      ...createElement,
                      description: env,
                    });
                  }}
                  value={createElement?.description}
                  init={{
                    height: 200,
                    menubar: false,
                    plugins: [
                      "a11ychecker",
                      "advlist",
                      "advcode",
                      "advtable",
                      "autolink",
                      "checklist",
                      "export",
                      "lists",
                      "link",
                      "image",
                      "charmap",
                      "preview",
                      "anchor",
                      "searchreplace",
                      "visualblocks",
                      "powerpaste",
                      "fullscreen",
                      "formatpainter",
                      "insertdatetime",
                      "media",
                      "table",
                      "help",
                      "wordcount",
                    ],
                     font_size_formats: "8px 10px 12px 14px 16px 18px 24px 36px 48px",
              toolbar:
                      "undo redo | casechange blocks fontsize | bold italic backcolor | " +
                      "alignleft aligncenter alignright alignjustify | " +
                      "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                  }}
                />
                <p className={styles.errorMsg}>{errors.descriptionError}</p>
              </Form.Group>
              <Form.Group>
                <Form.Label>Modal Description</Form.Label>
                <Editor
                  tinymceScriptSrc={"/tinymce/tinymce.min.js"}
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  onEditorChange={(env) => {
                    setCreateElement({
                      ...createElement,
                      modal_description: env,
                    });
                  }}
                  value={createElement?.modal_description}
                  init={{
                    height: 200,
                    menubar: false,
                    plugins: [
                      "a11ychecker",
                      "advlist",
                      "advcode",
                      "advtable",
                      "autolink",
                      "checklist",
                      "export",
                      "lists",
                      "link",
                      "image",
                      "charmap",
                      "preview",
                      "anchor",
                      "searchreplace",
                      "visualblocks",
                      "powerpaste",
                      "fullscreen",
                      "formatpainter",
                      "insertdatetime",
                      "media",
                      "table",
                      "help",
                      "wordcount",
                    ],
                     font_size_formats: "8px 10px 12px 14px 16px 18px 24px 36px 48px",
              toolbar:
                      "undo redo | casechange blocks fontsize | bold italic backcolor | " +
                      "alignleft aligncenter alignright alignjustify | " +
                      "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                  }}
                />
                <p className={styles.errorMsg}>
                  {errors.modalDescriptionError}
                </p>
              </Form.Group>
            </Form>
            <Card>
              {isImageLoading ? (
                <Spinner
                  className="align-self-center m-5"
                  animation="border"
                  size="sm"
                />
              ) : selectedImage ? (
                <Card.Img
                  variant="top"
                  src={selectedImage}
                  className={styles.card_img}
                  onClick={() => {
                    let imageUploader = document.getElementById(
                      "product_image_uploader"
                    );
                    if (imageUploader) {
                      imageUploader.click();
                    }
                  }}
                />
              ) : (
                <input
                  onChange={(event: any) => {
                    setIsImageLoading(true);
                    uploadImageToS3Bucket(
                      event.target.files[0],
                      (data: any) => {
                        setSelectedImage(data);
                        setCreateElement({ ...createElement, modal_img: data });
                        setIsImageLoading(false);
                      },
                      () => {
                        setIsImageLoading(false);
                      }
                    );
                  }}
                  type={"file"}
                  accept=".png"
                  id="product_image_uploader"
                />
              )}
            </Card>

            <Row>
              {selectedImage ? (
                <Col sm={"6"}>
                  <Button
                    onClick={() => {
                      setItems([...items, createElement]);
                      setIsCreateModalOpen(!isCreateModalOpen);
                      setCreateElement({});
                      setSelectedImage(undefined);
                      onSubmitEditOrCreate({
                        id: content?.services?._id,
                        title,
                        items: [...items, createElement],
                      });
                    }}
                    className="w-100 mt-2"
                    variant="success"
                  >
                    Add
                  </Button>
                </Col>
              ) : null}
            </Row>
          </Modal.Body>
        </Modal>
      </>
    );
  };

  return (
    <Container className="mt-5">
      <Form className="w-lg-75">
        <Form.Group>
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            value={title}
            onChange={(event) => {
              setTitle(event.target.value);
              setErrors({ ...errors, titleError: "" });
            }}
            placeholder="Title"
            className={styles.formItem}
          />
          <p className={styles.errorMsg}>{errors.titleError}</p>
        </Form.Group>
      </Form>
      <Row className="justify-content-end">
        <Col sm={2}>
          <Button
            onClick={() => {
              setIsCreateModalOpen(true);
            }}
            variant="success"
            className="mb-2 w-100"
          >
            Add New
          </Button>
        </Col>
      </Row>
      <Table bordered responsive hover size="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>Title</th>
            <th>Description</th>
            <th>Modal Description</th>
            <th>Modal Image</th>
            <th>{<FontAwesomeIcon className="ps-2 pe-2" icon={faGear} />}</th>
          </tr>
        </thead>
        <tbody>
          {items?.map((item: any, index: number) => {
            return (
              <tr>
                <td>{index + 1}</td>
                <td>{item?.title}</td>
                <td>{parse(item?.description || "")}</td>
                <td>{parse(item?.modal_description|| "")}</td>
                <td>
                  {
                    <Image
                      className={styles.image_preview}
                      src={item.modal_img}
                    />
                  }
                </td>
                <td>
                  <FontAwesomeIcon
                    onClick={() => {
                      setIsEditModalOpen(true);
                      setSelectedElement(item);
                      setSelectedElementIndex(index);
                    }}
                    className="ps-2 pe-2"
                    role={"button"}
                    icon={faEdit}
                  />
                  <FontAwesomeIcon
                    onClick={() => {
                      let _items = [...items];
                      _items.splice(index, 1);
                      setItems(_items);
                      onSubmitEditOrCreate({
                        id: content?.services?._id,
                        title,
                        items: _items,
                      });
                    }}
                    className="ps-2 pe-2"
                    role={"button"}
                    icon={faTrash}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Button onClick={onSubmitForm} className="ps-5 pe-5 mt-5">
        {isLoadingSubmission ? (
          <Spinner size={"sm"} animation="border" variant="primary" />
        ) : (
          "Update"
        )}
      </Button>
      {renderEditModal()}
      {renderCreateModal()}
    </Container>
  );
};

export { ServicesAdmin };
