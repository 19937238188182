import React, { useEffect, useState } from "react";
import { Button, Container, Form, Spinner } from "react-bootstrap";
import styles from "./SocialMediaAdmin.module.css";
import { getContentApi, updateSocialMediaApi } from "../../apis";

const SocialMediaAdmin = () => {
  const [content, setContent] = useState<any>({});
  const [facebookLink, setFacebookLink] = useState<string>("");
  const [twitterLink, setTwitterLink] = useState<string>("");
  const [linkedInLink, setLinkedInLink] = useState<string>("");
  const [youtubeLink, setYoutubeLink] = useState<string>("");
  const [githubLink, setGithubLink] = useState<string>("");

  const [errors, setErrors] = useState<any>({
    facebookLinkError: "",
    youtubeLinkError: "",
    twitterLinkError: "",
    linkedinLinkError: "",
    githubLinkError: "",
  });
  const [isLoadingSubmission, setIsLoadingSubmission] =
    useState<boolean>(false);

  const onFetchContent = () => {
    getContentApi(
      (data) => {
        setContent(data.data);
      },
      () => {}
    );
  };

  useEffect(() => {
    onFetchContent();
  }, []);

  const validate = () => {
    let isValid = true;
    let _error = {
      facebookLinkError: "",
      youtubeLinkError: "",
      twitterLinkError: "",
      linkedinLinkError: "",
      githubLinkError: "",
    };
    if (facebookLink?.trim()?.length === 0) {
      isValid = false;
      _error = { ..._error, facebookLinkError: "Field cannot be empty !" };
    }
    if (linkedInLink?.trim()?.length === 0) {
      isValid = false;
      _error = { ..._error, linkedinLinkError: "Field cannot be empty !" };
    }
    if (twitterLink?.trim()?.length === 0) {
      isValid = false;
      _error = { ..._error, twitterLinkError: "Field cannot be empty !" };
    }
    if (youtubeLink?.trim()?.length === 0) {
      isValid = false;
      _error = { ..._error, youtubeLinkError: "Field cannot be empty !" };
    }
    if (githubLink?.trim()?.length === 0) {
      isValid = false;
      _error = { ..._error, githubLinkError: "Field cannot be empty !" };
    }
    setErrors(_error);
    return isValid;
  };

  const onSubmitForm = () => {
    if (validate()) {
      setIsLoadingSubmission(true);
      const data = {
        id: content?.social?._id,
        facebook_link: facebookLink,
        twitter_link: twitterLink,
        linkedin_link: linkedInLink,
        youtube_link: youtubeLink,
        github_link: githubLink,
      };
      updateSocialMediaApi(
        data,
        () => {
          onFetchContent();
          setIsLoadingSubmission(false);
        },
        () => {
          setIsLoadingSubmission(false);
        }
      );
    }
  };

  useEffect(() => {
    if (content?.social) {
      setFacebookLink(content?.social?.facebook_link);
      setTwitterLink(content?.social?.twitter_link);
      setLinkedInLink(content?.social?.linkedin_link);
      setYoutubeLink(content?.social?.youtube_link);
      setGithubLink(content?.social?.github_link);
    }
  }, [content]);

  return (
    <Container className="mt-5">
      <Form className="w-lg-75">
        <Form.Group>
          <Form.Label>Facebook Link</Form.Label>
          <Form.Control
            type="text"
            value={facebookLink}
            onChange={(event) => {
              setFacebookLink(event.target.value);
              setErrors({ ...errors, facebookLinkError: "" });
            }}
            placeholder="Facebook link"
            className={styles.formItem}
          />
          <p className={styles.errorMsg}>{errors.facebookLinkError}</p>
        </Form.Group>
        <Form.Group>
          <Form.Label>Twitter Link</Form.Label>
          <Form.Control
            type="text"
            value={twitterLink}
            onChange={(event) => {
              setTwitterLink(event.target.value);
              setErrors({ ...errors, twitterLinkError: "" });
            }}
            placeholder="Twitter link"
            className={styles.formItem}
          />
          <p className={styles.errorMsg}>{errors.twitterLinkError}</p>
        </Form.Group>
        <Form.Group>
          <Form.Label>LinkedIn Link</Form.Label>
          <Form.Control
            type="text"
            value={linkedInLink}
            onChange={(event) => {
              setLinkedInLink(event.target.value);
              setErrors({ ...errors, linkedinLinkError: "" });
            }}
            placeholder="Linkedin link"
            className={styles.formItem}
          />
          <p className={styles.errorMsg}>{errors.linkedinLinkError}</p>
        </Form.Group>
        <Form.Group>
          <Form.Label>Youtube Link</Form.Label>
          <Form.Control
            type="text"
            value={youtubeLink}
            onChange={(event) => {
              setYoutubeLink(event.target.value);
              setErrors({ ...errors, youtubeLinkError: "" });
            }}
            placeholder="Youtube link"
            className={styles.formItem}
          />
          <p className={styles.errorMsg}>{errors.youtubeLinkError}</p>
        </Form.Group>
        <Form.Group>
          <Form.Label>Github Link</Form.Label>
          <Form.Control
            type="text"
            value={githubLink}
            onChange={(event) => {
              setGithubLink(event.target.value);
              setErrors({ ...errors, githubLinkError: "" });
            }}
            placeholder="Github link"
            className={styles.formItem}
          />
          <p className={styles.errorMsg}>{errors.githubLinkError}</p>
        </Form.Group>

        <Button onClick={onSubmitForm} className="ps-5 pe-5 mt-5">
          {isLoadingSubmission ? (
            <Spinner size={"sm"} animation="border" variant="primary" />
          ) : (
            "Update"
          )}
        </Button>
      </Form>
    </Container>
  );
};

export { SocialMediaAdmin };
