import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Table,
  Modal,
  ModalBody,
  Form,
  ModalFooter,
  ModalHeader,
} from "react-bootstrap";
import {
  addUpdateBlogCategoryApi,
  deleteBlogCategoryApi,
  getBlogCategoryApi,
} from "../../apis";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

const CategoryAdmin = (props: any) => {
  const [category, setCategory] = useState<any>();
  const [showAddModal, setShowAddModal] = useState(false);
  const [categories, setCategories] = useState<any>([]);

  const onCategorySubmit = async () => {
    const result = await addUpdateBlogCategoryApi(category);
    if (result) {
      const response = await getBlogCategoryApi();
      if (response) {
        setCategories(response);
      }
      setShowAddModal(false);
      setCategory(null);
    }
  };

  const onFetchContent = async () => {
    const result = await getBlogCategoryApi();
    setCategories(result);
  };

  const handleDeleteBlogCategory = async (item: any) => {
    const result = await deleteBlogCategoryApi(item?._id);
    if (result) {
      const response = await getBlogCategoryApi();
      if (response) {
        setCategories(response);
      }
    }
  };

  useEffect(() => {
    onFetchContent();
  }, []);

  const renderAddCategoryModal = () => {
    return (
      <Modal
        show={showAddModal}
        onHide={() => {
          setCategory(null);
          setShowAddModal(false);
        }}
      >
        <ModalHeader closeButton>Manage Blog Category</ModalHeader>
        <ModalBody>
          <Form.Group>
            <Form.Label>Category Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Blog Category Name"
              value={category?.name || ""}
              onChange={(e) =>
                setCategory({
                  ...(category && { ...category }),
                  name: e.target.value,
                })
              }
            />
          </Form.Group>
          <ModalFooter>
            <Button
              variant="secondary"
              onClick={() => {
                setCategory(null);
                setShowAddModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="success"
              className="ms-3"
              onClick={onCategorySubmit}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalBody>
      </Modal>
    );
  };
  return (
    <Container className="mt-5">
      <Row className="justify-content-end">
        <Col sm={2}>
          <Button
            onClick={() => {
              setShowAddModal(true);
            }}
            variant="success"
            className="mb-2 w-100"
          >
            Add New
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <Table bordered responsive hover>
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {categories?.map((item: any, index: number) => {
                return (
                  <tr key={item?.name + index}>
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>
                      <FontAwesomeIcon
                        className="ps-2 pe-2"
                        role={"button"}
                        icon={faEdit}
                        onClick={() => {
                          setCategory(item);
                          setShowAddModal(true);
                        }}
                      />
                      <FontAwesomeIcon
                        onClick={() => {
                          handleDeleteBlogCategory(item);
                        }}
                        className="ps-2 pe-2"
                        role={"button"}
                        icon={faTrash}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
      {renderAddCategoryModal()}
    </Container>
  );
};

export { CategoryAdmin };
