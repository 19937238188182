import React, { useEffect, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Row, Col, Form, Button, Table, Image } from "react-bootstrap";
import Container from "react-bootstrap/esm/Container";
import {
  editBlogApi,
  getBlogByIdApi,
  getBlogCategoryApi,
  getBlogsApi,
} from "../../apis";
import { useContentLogic } from "../../hooks/useContentLogic";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const EditBlog = () => {
  const editorRef = useRef<any>(null);
  const [categories, setCategories] = useState<any>();
  const [blog, setBlog] = useState({
    title: "",
    excerpt: "",
    blogCategory: "",
    content: "",
    featureImage: "",
  });

  const { id } = useParams();

  const { uploadImageToS3Bucket } = useContentLogic();
  const handleImageChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      uploadImageToS3Bucket(
        file,
        (response: any) => {
          setBlog({ ...blog, featureImage: response });
        },
        (errors: any) => {
          console.log(errors);
        }
      );
    }
  };

  const getCategories = async () => {
    const result = await getBlogCategoryApi();
    if (result?.length) {
      setCategories(result);
    }
  };

  const handleSubmit = async () => {
    await editBlogApi(blog);
  };

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    getBlogByIdApi(
      id,
      (response) => {
        setBlog(response.data)
      },
      (error) => {
        toast.error(error.message, { autoClose: 3000 });
      }
    );
  }, [id]);
  return (
    <Container className="mt-5">
      <Row className="justify-content-end">
        <Button
          variant="success"
          onClick={() => {
            handleSubmit();
          }}
          className="w-auto ps-5 pe-5 me-3"
        >
          Save
        </Button>
      </Row>
      <Row>
        <Col xs="12" md="6">
          <Form.Group className="pb-2">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              value={blog.title}
              onChange={(event) => {
                setBlog({ ...blog, title: event.target.value });
              }}
              placeholder="Enter Blog Title"
            />
          </Form.Group>
          <Form.Group className="pb-2">
            <Form.Label>Excerpt</Form.Label>
            <Form.Control
              type="text"
              value={blog.excerpt}
              onChange={(event) => {
                setBlog({ ...blog, excerpt: event.target.value });
              }}
              placeholder="Enter Blog Excerpt"
            />
          </Form.Group>
          <Form.Group className="pb-2">
            <Form.Label>Select Category</Form.Label>
            <Form.Select
              value={blog.blogCategory}
              onChange={(e) => {
                setBlog({ ...blog, blogCategory: e.target.value });
              }}
            >
              {categories?.map((x: any, index: number) => {
                return <option value={x?._id}>{x.name}</option>;
              })}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col xs="12" md="6">
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Select an image to upload</Form.Label>
            <Form.Control
              type="file"
              accept="image/*"
              onChange={handleImageChange}
            />
          </Form.Group>

          {blog?.featureImage && (
            <div className="mb-3">
              <Image
                src={blog.featureImage}
                alt="Selected Image"
                thumbnail
                fluid
                width={200}
              />
            </div>
          )}
        </Col>
        <Col xs="12">
          <Form.Group>
            <Form.Label>Content</Form.Label>
            <Editor
              tinymceScriptSrc={"/tinymce/tinymce.min.js"}
              onInit={(evt, editor) => (editorRef.current = editor)}
              onEditorChange={(env) => {
                setBlog({ ...blog, content: env });
                //   setErrors({ ...errors, firstTitleError: "" });
              }}
              value={blog?.content || ""}
              init={{
                height: 500,
                menubar: false,
                plugins: [
                  "a11ychecker",
                  "advlist",
                  "advcode",
                  "advtable",
                  "autolink",
                  "checklist",
                  "export",
                  "lists",
                  "link",
                  "image",
                  "charmap",
                  "preview",
                  "anchor",
                  "searchreplace",
                  "visualblocks",
                  "powerpaste",
                  "fullscreen",
                  "formatpainter",
                  "insertdatetime",
                  "media",
                  "table",
                  "help",
                  "wordcount",
                ],
                font_size_formats:
                  "8px 10px 12px 14px 16px 18px 24px 36px 48px",
                toolbar:
                  "undo redo | casechange blocks fontsize | bold italic backcolor | " +
                  "alignleft aligncenter alignright alignjustify | " +
                  "bullist numlist checklist outdent indent | removeformat | a11ycheck code table media | help",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
            />
          </Form.Group>
        </Col>
      </Row>
    </Container>
  );
};

export { EditBlog };
