import React, { useEffect, useRef, useState } from "react";
import { Button, Container, Form, Image, Row, Spinner } from "react-bootstrap";
import styles from "./AboutUsAdmin.module.css";
import { getContentApi, updateAboutUsContentApi } from "../../apis";
import { useContentLogic } from "../../hooks/useContentLogic";
import { Editor } from "@tinymce/tinymce-react";

const AboutUsAdmin = () => {
  const [content, setContent] = useState<any>({});
  const [firstSectionDescription, setFirstSectionDescription] =
    useState<string>("");
  const [secondSectionDescription, setSecondSectionDescription] =
    useState<string>("");
  const [firstSectionTitle, setFirstSectionTitle] = useState<string>("");
  const [secondSectionTitle, setSecondSectionTitle] = useState<string>("");
  const [items, setItems] = useState<Array<any>>([]);
  const [errors, setErrors] = useState<any>({
    firstDescriptionError: "",
    secondDescriptionError: "",
  });
  const [isLoadingSubmission, setIsLoadingSubmission] =
    useState<boolean>(false);
  const [isFirstImageLoading, setIsFirstImageLoading] =
    useState<boolean>(false);
  const [isSecondImageLoading, setIsSecondImageLoading] =
    useState<boolean>(false);
  const { uploadImageToS3Bucket } = useContentLogic();
  const onFetchContent = () => {
    getContentApi(
      (data) => {
        setContent(data.data);
      },
      () => {}
    );
  };

  useEffect(() => {
    onFetchContent();
  }, []);

  const validate = () => {
    let isValid = true;
    let _error = {
      titleError: "",
    };
    if (firstSectionDescription?.trim()?.length === 0) {
      isValid = false;
      _error = { ..._error, titleError: "Field cannot be empty !" };
    }
    setErrors(_error);
    return isValid;
  };

  const onSubmitForm = () => {
    if (validate()) {
      setIsLoadingSubmission(true);
      let _items = [...items];
      _items[0].title = firstSectionTitle;
      _items[0].description = firstSectionDescription;
      _items[1].title = secondSectionTitle;
      _items[1].description = secondSectionDescription;
      const data = {
        id: content?.aboutus?._id,
        items: _items,
      };
      updateAboutUsContentApi(
        data,
        () => {
          onFetchContent();
          setIsLoadingSubmission(false);
        },
        () => {
          setIsLoadingSubmission(false);
        }
      );
    }
  };

  useEffect(() => {
    if (content?.aboutus) {
      setItems(content?.aboutus?.items || []);
      setFirstSectionDescription(content?.aboutus?.items[0]?.description);
      setSecondSectionDescription(content?.aboutus?.items[1]?.description);
      setFirstSectionTitle(content?.aboutus?.items[0]?.title);
      setSecondSectionTitle(content?.aboutus?.items[1]?.title);
    }
  }, [content]);

  const editorRef = useRef<any>(null);

  return (
    <Container className="mt-5">
      <Form className="w-lg-75">
        <Form.Group>
          <Form.Label>First section Image</Form.Label>
        </Form.Group>
        {isFirstImageLoading ? (
          <Spinner
            className="align-self-center m-5"
            animation="border"
            size="sm"
          />
        ) : items[0]?.image_url ? (
          <Image
            className={styles.card_img}
            src={items[0]?.image_url}
            onClick={() => {
              let imageUploader = document.getElementById(
                "product_image_uploader_1"
              );
              if (imageUploader) {
                imageUploader.click();
              }
            }}
          />
        ) : (
          <input
            onChange={(event: any) => {
              setIsFirstImageLoading(true);
              uploadImageToS3Bucket(
                event.target.files[0],
                (data: any) => {
                  let _items = [...items];
                  _items[0].image_url = data;
                  setItems(_items);
                  setIsFirstImageLoading(false);
                },
                () => {
                  setIsFirstImageLoading(false);
                }
              );
            }}
            type={"file"}
            accept=".png"
            className="border mt-1 mb-2"
            id="product_image_uploader"
          />
        )}

        <Row>
          <input
            onChange={(event: any) => {
              setIsFirstImageLoading(true);
              uploadImageToS3Bucket(
                event.target.files[0],
                (data: any) => {
                  let _items = [...items];
                  _items[0].image_url = data;
                  setItems(_items);
                  setIsFirstImageLoading(false);
                },
                () => {
                  setIsFirstImageLoading(false);
                }
              );
            }}
            type={"file"}
            accept=".png"
            style={{ display: "none" }}
            id="product_image_uploader_1"
          />
        </Row>
        <Form.Group>
          <Form.Label>First section Title</Form.Label>
          {/* <Form.Control
            type="text"
            value={firstSectionTitle}
            onChange={(event) => {
              setFirstSectionTitle(event.target.value);
              setErrors({ ...errors, firstTitleError: "" });
            }}
            placeholder="Enter text here !"
            className={styles.formItem}
          /> */}

          <Editor
            tinymceScriptSrc={"/tinymce/tinymce.min.js"}
            onInit={(evt, editor) => (editorRef.current = editor)}
            onEditorChange={(env) => {
              setFirstSectionTitle(env);
              setErrors({ ...errors, firstTitleError: "" });
            }}
            value={firstSectionTitle}
            init={{
              height: 200,
              menubar: false,
              plugins: [
                "a11ychecker",
                "advlist",
                "advcode",
                "advtable",
                "autolink",
                "checklist",
                "export",
                "lists",
                "link",
                "image",
                "charmap",
                "preview",
                "anchor",
                "searchreplace",
                "visualblocks",
                "powerpaste",
                "fullscreen",
                "formatpainter",
                "insertdatetime",
                "media",
                "table",
                "help",
                "wordcount",
              ],
              font_size_formats: "8px 10px 12px 14px 16px 18px 24px 36px 48px",
              toolbar:
                "undo redo | casechange blocks fontsize | bold italic backcolor | " +
                "alignleft aligncenter alignright alignjustify | " +
                "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          />

          <p className={styles.errorMsg}>{errors.firstTitleError}</p>
        </Form.Group>
        <Form.Group>
          <Form.Label>First section Description</Form.Label>
          <Editor
            tinymceScriptSrc={"/tinymce/tinymce.min.js"}
            onInit={(evt, editor) => (editorRef.current = editor)}
            onEditorChange={(env) => {
              setFirstSectionDescription(env);
              setErrors({ ...errors, firstTitleError: "" });
            }}
            value={firstSectionDescription}
            init={{
              height: 200,
              menubar: false,
              plugins: [
                "a11ychecker",
                "advlist",
                "advcode",
                "advtable",
                "autolink",
                "checklist",
                "export",
                "lists",
                "link",
                "image",
                "charmap",
                "preview",
                "anchor",
                "searchreplace",
                "visualblocks",
                "powerpaste",
                "fullscreen",
                "formatpainter",
                "insertdatetime",
                "media",
                "table",
                "help",
                "wordcount",
              ],
              font_size_formats: "8px 10px 12px 14px 16px 18px 24px 36px 48px",
              toolbar:
                "undo redo | casechange blocks fontsize | bold italic backcolor | " +
                "alignleft aligncenter alignright alignjustify | " +
                "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          />
          {/* <Form.Control
            type="text"
            as={"textarea"}
            value={firstSectionDescription}
            onChange={(event) => {
              setFirstSectionDescription(event.target.value);
              setErrors({ ...errors, firstDescriptionError: "" });
            }}
            placeholder="Enter text here !"
            className={styles.formItem}
          /> */}
          <p className={styles.errorMsg}>{errors.firstDescriptionError}</p>
        </Form.Group>

        <Form.Group>
          <Form.Label>Second section Image</Form.Label>
        </Form.Group>
        {isSecondImageLoading ? (
          <Spinner
            className="align-self-center m-5"
            animation="border"
            size="sm"
          />
        ) : items[1]?.image_url ? (
          <Image
            className={styles.card_img}
            src={items[1]?.image_url}
            onClick={() => {
              let imageUploader = document.getElementById(
                "product_image_uploader_2"
              );
              if (imageUploader) {
                imageUploader.click();
              }
            }}
          />
        ) : (
          <input
            onChange={(event: any) => {
              setIsSecondImageLoading(true);
              uploadImageToS3Bucket(
                event.target.files[0],
                (data: any) => {
                  let _items = [...items];
                  _items[1].image_url = data;
                  setItems(_items);
                  setIsSecondImageLoading(false);
                },
                () => {
                  setIsSecondImageLoading(false);
                }
              );
            }}
            type={"file"}
            accept=".png"
            className="border mt-1 mb-2"
            id="product_image_uploader"
          />
        )}
        <Row>
          <input
            onChange={(event: any) => {
              setIsSecondImageLoading(true);
              uploadImageToS3Bucket(
                event.target.files[0],
                (data: any) => {
                  let _items = [...items];
                  _items[1].image_url = data;
                  setItems(_items);
                  setIsSecondImageLoading(false);
                },
                () => {
                  setIsSecondImageLoading(false);
                }
              );
            }}
            type={"file"}
            accept=".png"
            style={{ display: "none" }}
            id="product_image_uploader_2"
          />
        </Row>
        <Form.Group>
          <Form.Label>Second section Title</Form.Label>
          <Editor
            tinymceScriptSrc={"/tinymce/tinymce.min.js"}
            onInit={(evt, editor) => (editorRef.current = editor)}
            onEditorChange={(env) => {
              setSecondSectionTitle(env);
              setErrors({ ...errors, firstTitleError: "" });
            }}
            value={secondSectionTitle}
            init={{
              height: 200,
              menubar: false,
              plugins: [
                "a11ychecker",
                "advlist",
                "advcode",
                "advtable",
                "autolink",
                "checklist",
                "export",
                "lists",
                "link",
                "image",
                "charmap",
                "preview",
                "anchor",
                "searchreplace",
                "visualblocks",
                "powerpaste",
                "fullscreen",
                "formatpainter",
                "insertdatetime",
                "media",
                "table",
                "help",
                "wordcount",
              ],
              font_size_formats: "8px 10px 12px 14px 16px 18px 24px 36px 48px",
              toolbar:
                "undo redo | casechange blocks fontsize | bold italic backcolor | " +
                "alignleft aligncenter alignright alignjustify | " +
                "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          />
          {/* <Form.Control
            type="text"
            value={secondSectionTitle}
            onChange={(event) => {
              setSecondSectionTitle(event.target.value);
              setErrors({ ...errors, secondTitleError: "" });
            }}
            placeholder="Enter text here !"
            className={styles.formItem}
          /> */}
          <p className={styles.errorMsg}>{errors.secondTitleError}</p>
        </Form.Group>
        <Form.Group>
          <Form.Label>Second section Description</Form.Label>
          <Editor
            tinymceScriptSrc={"/tinymce/tinymce.min.js"}
            onInit={(evt, editor) => (editorRef.current = editor)}
            onEditorChange={(env) => {
              setSecondSectionDescription(env);
              setErrors({ ...errors, firstTitleError: "" });
            }}
            value={secondSectionDescription}
            init={{
              height: 200,
              menubar: false,
              plugins: [
                "a11ychecker",
                "advlist",
                "advcode",
                "advtable",
                "autolink",
                "checklist",
                "export",
                "lists",
                "link",
                "image",
                "charmap",
                "preview",
                "anchor",
                "searchreplace",
                "visualblocks",
                "powerpaste",
                "fullscreen",
                "formatpainter",
                "insertdatetime",
                "media",
                "table",
                "help",
                "wordcount",
              ],
              font_size_formats: "8px 10px 12px 14px 16px 18px 24px 36px 48px",
              toolbar:
                "undo redo | casechange blocks fontsize | bold italic backcolor | " +
                "alignleft aligncenter alignright alignjustify | " +
                "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          />
          {/* <Form.Control
            type="text"
            as={"textarea"}
            value={secondSectionDescription}
            onChange={(event) => {
              setSecondSectionDescription(event.target.value);
              setErrors({ ...errors, secondDescriptionError: "" });
            }}
            placeholder="Enter text here !"
            className={styles.formItem}
          /> */}
          <p className={styles.errorMsg}>{errors.secondDescriptionError}</p>
        </Form.Group>
      </Form>

      <Button onClick={onSubmitForm} className="ps-5 pe-5 mt-5">
        {isLoadingSubmission ? (
          <Spinner size={"sm"} animation="border" variant="primary" />
        ) : (
          "Update"
        )}
      </Button>
    </Container>
  );
};

export { AboutUsAdmin };
