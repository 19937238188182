import React, { useEffect } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import styles from "./AboutUs.module.css";
import parse from 'html-react-parser';

const AboutUs = (props: any) => {
  const { content } = props;
  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      if (window.scrollY > window.innerHeight / 2 - 100) {
        document
          .getElementById("site-header")
          ?.classList?.add("header-black-bg");
      } else {
        document
          .getElementById("site-header")
          ?.classList?.remove("header-black-bg");
      }
    });
    return () => {
      window.removeEventListener("scroll", () => {});
    };
  }, []);

  return (
    <>
      <Row>
        <Col className={styles.aboutUsBanner}>
          <h1 className={styles.bannerHeading}>
            About <br />
            <span className="theme-color-red">RORO Technology</span>
          </h1>
        </Col>
      </Row>
      <Row>
        <div>
          <Container>
            {content?.aboutus?.items?.map((item: any, index: number) => {
              return index % 2 === 0 ? (
                <Row key={index} className="mt-5">
                  <Col
                    lg="3"
                    xs="12"
                    className="d-flex flex-column align-items-center"
                  >
                    <Image src={item?.image_url} className="rounded w-100" />
                  </Col>
                  <Col lg="9" xs="12">
                    <div>{parse(item?.title)}</div>
                    <p className="text-justify">{parse(item?.description)}</p>
                  </Col>
                </Row>
              ) : (
                <Row key={index} className="mt-5 mb-5">
                  <Col lg="9" xs="12">
                    <h3>{parse(item?.title)}</h3>
                    <p className="text-justify">{parse(item?.description)}</p>
                  </Col>
                  <Col
                    lg="3"
                    xs="12"
                    className="d-flex flex-column align-items-center"
                  >
                    <Image src={item?.image_url} className="rounded w-100" />
                  </Col>
                </Row>
              );
            })}
          </Container>
        </div>
      </Row>
    </>
  );
};

export { AboutUs };
