import React, { useEffect, useState } from "react";
import {
  Container,
  Nav,
  Navbar,
  Row,
  Image,
  Col,
  ModalHeader,
  ModalBody,
  Modal,
} from "react-bootstrap";
import { Outlet, useNavigate } from "react-router-dom";
import { LOGO, LOGO_2 } from "../../assets/images";
import parse from "html-react-parser";
import styles from "./FullPageLayout.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as FacebookSVG } from "./../../assets/images/facebook.svg";
import { ReactComponent as TwitterSVG } from "./../../assets/images/twitter.svg";
import { ReactComponent as LinkedSVG } from "./../../assets/images/linkedin.svg";
import { ReactComponent as YoutubeSVG } from "./../../assets/images/youtube.svg";
import { ReactComponent as GithubSVG } from "./../../assets/images/github.svg";

const FullPageLayout = (props: any) => {
  const { content } = props;
  const [openedModalName, setOpenedModalName] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const header = document.getElementById("site-header");
    const handleScroll = () => {
      if (window.scrollY > 10) {
        header?.classList.add("header-black-bg");
      } else {
        if (!window.location.pathname.includes("blogs")) {
          header?.classList.remove("header-black-bg");
        }
      }
    };
  
    if (header) {
      if (window.location.pathname.includes("blogs")) {
        header.classList.add("header-black-bg");
      } else {
        window.addEventListener("scroll", handleScroll);
      }
    }
  
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [window.location.pathname]);
  

  const onClickHeaderLink = (section_id: string) => {
    if (section_id === "blogs") {
      navigate(`/blogs`);
      return;
    }
    if (section_id !== "blogs" && window.location.pathname.includes("blogs")) {
      navigate(`/?section=${section_id}`);
      return;
    }
    let element = document.getElementById(section_id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const openInNewTab = (url: string) => {
    if (url) {
      window.open(url, "_blank", "noreferrer");
    }
  };

  const renderHeader = () => {
    return (
      <Row className="pt-0 pt-sm-4" id="site-header">
        <Navbar collapseOnSelect expand="lg">
          <Container>
            <Navbar.Brand onClick={() => onClickHeaderLink("home")}>
              <Image src={LOGO} alt="roro technology" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav">
              <FontAwesomeIcon size="1x" color="#fff" icon={faBars} />
            </Navbar.Toggle>
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className={styles.pageNavigationNav + " m-auto"}>
                <Nav.Link
                  as="button"
                  onClick={() => onClickHeaderLink("home")}
                  className="text-white font-14 no-background no-border"
                >
                  Home
                </Nav.Link>
                <Nav.Link
                  as="button"
                  onClick={() => onClickHeaderLink("product_delivery")}
                  className="text-white font-14  no-background no-border"
                >
                  Product Delivery
                </Nav.Link>
                <Nav.Link
                  as="button"
                  onClick={() => onClickHeaderLink("portfolio")}
                  className="text-white font-14  no-background no-border"
                >
                  Portfolio
                </Nav.Link>
                <Nav.Link
                  as="button"
                  onClick={() => onClickHeaderLink("services")}
                  className="text-white font-14  no-background no-border"
                >
                  Services
                </Nav.Link>
                <Nav.Link
                  as="button"
                  onClick={() => onClickHeaderLink("about-us")}
                  className="text-white font-14  no-background no-border"
                >
                  About Us
                </Nav.Link>
                <Nav.Link
                  as="button"
                  onClick={() => onClickHeaderLink("team")}
                  className="text-white font-14  no-background no-border"
                >
                  Team
                </Nav.Link>
                <Nav.Link
                  as="button"
                  onClick={() => onClickHeaderLink("contact_us")}
                  className="text-white font-14  no-background no-border"
                >
                  Contact Us
                </Nav.Link>
                <Nav.Link
                  as="button"
                  onClick={() => onClickHeaderLink("blogs")}
                  className="text-white font-14  no-background no-border"
                >
                  Blogs
                </Nav.Link>
              </Nav>
              <Nav className={styles.socialMediaLinksNav}>
                <Nav.Link
                  onClick={() => openInNewTab(content?.social?.facebook_link)}
                  className={styles.socialMediaLinks}
                >
                  {/* <Image src={FACEBOOK} /> */}
                  <FacebookSVG />
                </Nav.Link>
                <Nav.Link
                  onClick={() => openInNewTab(content?.social?.twitter_link)}
                  className={styles.socialMediaLinks}
                >
                  {/* <Image src={TWITTER} /> */}
                  <TwitterSVG />
                </Nav.Link>
                <Nav.Link
                  onClick={() => openInNewTab(content?.social?.linkedin_link)}
                  className={styles.socialMediaLinks}
                >
                  {/* <Image src={LINKEDIN} /> */}
                  <LinkedSVG />
                </Nav.Link>
                <Nav.Link
                  onClick={() => openInNewTab(content?.social?.youtube_link)}
                  className={styles.socialMediaLinks}
                >
                  {/* <Image src={YOUTUBE} /> */}
                  <YoutubeSVG />
                </Nav.Link>
                <Nav.Link
                  onClick={() => openInNewTab(content?.social?.github_link)}
                >
                  {/* <Image src={GITHUB} /> */}
                  <GithubSVG />
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Row>
    );
  };

  const renderFooter = () => {
    return (
      <>
        <Row className="pt-4 bg-black" id="site-footer">
          <Navbar collapseOnSelect expand="lg">
            <Container className="border-bottom pb-4 theme-border-color">
              <Navbar.Brand href="#home">
                <Image src={LOGO_2} alt="roro technology" />
              </Navbar.Brand>

              <Nav className={"ms-auto"}>
                <Nav.Link
                  as="button"
                  onClick={() => onClickHeaderLink("home")}
                  className="text-white font-14 no-background no-border"
                >
                  Home
                </Nav.Link>
                <Nav.Link
                  as="button"
                  onClick={() => onClickHeaderLink("product_delivery")}
                  className="text-white font-14  no-background no-border"
                >
                  Product Delivery
                </Nav.Link>
                <Nav.Link
                  as="button"
                  onClick={() => onClickHeaderLink("portfolio")}
                  className="text-white font-14  no-background no-border"
                >
                  Portfolio
                </Nav.Link>
                <Nav.Link
                  as="button"
                  onClick={() => onClickHeaderLink("services")}
                  className="text-white font-14  no-background no-border"
                >
                  Services
                </Nav.Link>
                <Nav.Link
                  as="button"
                  onClick={() => onClickHeaderLink("about-us")}
                  className="text-white font-14  no-background no-border"
                >
                  About Us
                </Nav.Link>
                <Nav.Link
                  as="button"
                  onClick={() => onClickHeaderLink("team")}
                  className="text-white font-14  no-background no-border"
                >
                  Team
                </Nav.Link>
                <Nav.Link
                  as="button"
                  onClick={() => onClickHeaderLink("contact_us")}
                  className="text-white font-14  no-background no-border"
                >
                  Contact Us
                </Nav.Link>
                <Nav.Link
                  as="button"
                  onClick={() => onClickHeaderLink("blogs")}
                  className="text-white font-14  no-background no-border"
                >
                  Blogs
                </Nav.Link>
              </Nav>
            </Container>
          </Navbar>
        </Row>
        <Row className="bg-black">
          <div>
            <Container className="pb-5">
              <Row>
                <Col xs="6">
                  <span className="font-12 fw-lighter text-white cursor-pointer">
                    © 2021-2023 RORO Technology. All rights reserved
                  </span>
                </Col>

                <Col xs="6" className="text-end">
                  <span
                    onClick={() => setOpenedModalName("termsAndCondition")}
                    className="font-12 fw-lighter text-white cursor-pointer"
                  >
                    Terms&Agreements
                  </span>
                  <span
                    onClick={() => setOpenedModalName("privacyPolicy")}
                    className="ms-3 font-12 fw-lighter text-white cursor-pointer"
                  >
                    Privacy Policy
                  </span>
                </Col>
              </Row>
            </Container>
          </div>
        </Row>
      </>
    );
  };

  const rendeTermsAndConditionModal = () => {
    return (
      <Modal
        show={
          openedModalName === "termsAndCondition" ||
          openedModalName === "privacyPolicy"
        }
        size="xl"
        backdrop
        centered
        contentClassName={styles.modalContent}
        onHide={() => setOpenedModalName("")}
      >
        <ModalHeader
          closeButton
          closeVariant="white"
          className="border-bottom-0"
        >
          <h3 className="w-100 text-center text-white">
            {openedModalName === "termsAndCondition"
              ? "Terms and conditions !"
              : "Privacy Policy !"}
          </h3>
        </ModalHeader>
        <ModalBody className="text-white fw-light">
          <Container>
            <Row>
              <Col xs="12">
                <p className="font-16 line-height-24">
                  {openedModalName === "termsAndCondition"
                    ? parse(
                        content?.privacyPolicy?.terms_and_conditions_text || ""
                      )
                    : parse(content?.privacyPolicy?.privacy_policy_text || "")}
                </p>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    );
  };

  return (
    <Container fluid style={{ position: "relative" }}>
      {renderHeader()}
      <Outlet />
      {renderFooter()}
      {rendeTermsAndConditionModal()}
    </Container>
  );
};

export { FullPageLayout };
