import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Table, Form } from "react-bootstrap";
import { getBlogByCategoryApi, getBlogCategoryApi } from "../../apis";
import { useNavigate } from "react-router-dom";

const BlogsAdmin = () => {
 const navigate = useNavigate();

  const [blogs, setBlogs] = useState<any>();
  const [categories, setCategories] = useState<any>();
  const [selectedCategory, setSelectedCategory] = useState<string>();

  const getCategories = async () => {
    const result = await getBlogCategoryApi();
    if (result?.length) {
      setCategories(result);
      setSelectedCategory(result[0]?._id)
    }
  };

  const getBlogsByCategory = async () => {
    if (selectedCategory) {
      const response = await getBlogByCategoryApi(selectedCategory);
      if (response) {
        setBlogs(response);
      }
    }
  };

  useEffect(() => {
    getBlogsByCategory();
  }, [selectedCategory]);

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <Container className="mt-5">
      <Row className="justify-content-between mb-4 align-items-end">
        <Col sm={2}>
          <Form.Group controlId="categorySelect">
            <Form.Label>Select Category</Form.Label>
            <Form.Select
              value={selectedCategory}
              onChange={(e) => {
                setSelectedCategory(e.target.value);
              }}
            >
              {categories?.map((x: any, index: number) => {
                return <option value={x?._id}>{x.name}</option>;
              })}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col sm={2}>
          <Button onClick={() => {navigate("/admin/blogs/create")}} variant="success" className="mb-2 w-100">
            Add New
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <Table bordered responsive hover>
            <thead>
              <tr>
                <th>S.No.</th>
                <th>title</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {blogs?.map((item: any, index: number) => {
                return (
                  <tr key={item?.name + index}>
                    <td>{index + 1}</td>
                    <td>{item.title}</td>
                    <td>
                      <FontAwesomeIcon
                        className="ps-2 pe-2"
                        role={"button"}
                        icon={faEdit}
                        onClick={() => {
                          navigate("/admin/blogs/edit/"+item._id)
                        }}
                      />
                      <FontAwesomeIcon
                        onClick={() => {}}
                        className="ps-2 pe-2"
                        role={"button"}
                        icon={faTrash}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export { BlogsAdmin };
