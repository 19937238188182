import React from "react";
import {
  faHouse,
  faBoxOpen,
  faStar,
  faBellConcierge,
  faAddressCard,
  faBars,
  faHashtag,
  faNoteSticky,
  faPerson,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import {
  useProSidebar,
  Sidebar,
  Menu,
  MenuItem,
  MenuItemStyles,
  CSSObject,
} from "react-pro-sidebar";
import { LOGO, LOGO_3 } from "../../assets/images";
import { Outlet, Link, useNavigate } from "react-router-dom";
import styles from "./AdminLayout.module.css";

const AdminLayout: React.FC = () => {
  const { toggleSidebar, collapseSidebar, broken, collapsed } = useProSidebar();
  const navigate = useNavigate();
  const menuRootStyle: CSSObject = {
    color: "white",
    hover: {
      backgroundColor: "#222222",
      color: "#44596e",
    },
    marginTop: 30,
  };

  const menuItemStyles: MenuItemStyles = {
    root: {
      fontSize: "13px",
      fontWeight: 400,
    },
    SubMenuExpandIcon: {
      color: "#b6b7b9",
    },
    subMenuContent: ({ level }) => ({
      backgroundColor: "#222222",
    }),
    button: {
      "&:hover": {
        backgroundColor: "#4b8ca8",
        color: "#b6c8d9",
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  const renderSidebar = () => {
    return (
      <Sidebar breakPoint="lg" backgroundColor="rgba(25, 27, 29, 1)">
        <div className="d-flex flex-column h-100">
          <div
            className={`w-100 d-flex justify-content-center align-items-center pt-4 pb-2`}
          >
            <Image src={collapsed ? LOGO_3 : LOGO} />
          </div>
          <Menu rootStyles={menuRootStyle} menuItemStyles={menuItemStyles}>
            <MenuItem
              component={<Link className={styles.link} to={"/admin/banner"} />}
              icon={<FontAwesomeIcon icon={faHouse} />}
            >
              Banner
            </MenuItem>
            <MenuItem
              component={
                <Link className={styles.link} to={"/admin/products"} />
              }
              icon={<FontAwesomeIcon icon={faBoxOpen} />}
            >
              Product Delivery
            </MenuItem>
            <MenuItem
              component={
                <Link className={styles.link} to={"/admin/portfolio"} />
              }
              icon={<FontAwesomeIcon icon={faStar} />}
            >
              Portfolio
            </MenuItem>
            <MenuItem
              component={
                <Link className={styles.link} to={"/admin/services"} />
              }
              icon={<FontAwesomeIcon icon={faBellConcierge} />}
            >
              Our Services
            </MenuItem>
            <MenuItem
              component={<Link className={styles.link} to={"/admin/team"} />}
              icon={<FontAwesomeIcon icon={faAddressCard} />}
            >
              Team
            </MenuItem>
            <MenuItem
              component={<Link className={styles.link} to={"/admin/about"} />}
              icon={<FontAwesomeIcon icon={faPerson} />}
            >
              About
            </MenuItem>
            <MenuItem
              component={
                <Link className={styles.link} to={"/admin/social-media"} />
              }
              icon={<FontAwesomeIcon icon={faHashtag} />}
            >
              Social Media
            </MenuItem>
            <MenuItem
              component={
                <Link className={styles.link} to={"/admin/blog-category"} />
              }
              icon={<FontAwesomeIcon icon={faHashtag} />}
            >
              Blog Category
            </MenuItem>
            <MenuItem
              component={
                <Link className={styles.link} to={"/admin/blogs"} />
              }
              icon={<FontAwesomeIcon icon={faHashtag} />}
            >
              Blogs
            </MenuItem>
            <MenuItem
              component={
                <Link className={styles.link} to={"/admin/privacy-policy"} />
              }
              icon={<FontAwesomeIcon icon={faNoteSticky} />}
            >
              Privacy Policy
            </MenuItem>
            <MenuItem
              component={
                <Link className={styles.link} to={"/admin/change-password"} />
              }
              icon={<FontAwesomeIcon icon={faNoteSticky} />}
            >
              Change Password
            </MenuItem>
          </Menu>
        </div>
      </Sidebar>
    );
  };

  return (
    <div className="d-flex vh-100 w-100">
      {renderSidebar()}
      <Container fluid className="ps-5 pe-5">
        <Row
          className={
            "pt-4 pb-3 align-items-center justify-content-between border-bottom p-0"
          }
        >
          <Col xs="2">
            <FontAwesomeIcon
              icon={faBars}
              className="font-24"
              role={"button"}
              onClick={() => {
                broken ? toggleSidebar() : collapseSidebar();
              }}
            />
          </Col>
          <Col xs="1">
            <Button
              onClick={() => {
                localStorage.removeItem("isLoggedIn");
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                navigate("/");
              }}
              variant="muted"
            >
              Logout
            </Button>
          </Col>
        </Row>
        <Row className={`${styles.content_container} overflow-auto`}>
          <Outlet />
        </Row>
      </Container>
    </div>
  );
};

export { AdminLayout };
